import { Ref, unref, watch } from "vue";
import { uniq } from "lodash";
import {
  DecoratedAsset,
  Form,
  FormConfig,
  MaybeRef,
  SubmitSelection,
  FormValues,
  FieldDescriptor,
  FormSubmitServerParams
} from "@/types";
import { buildAssetFormConfig, copyPropertyToForm } from "@/config/asset-form";
import { FormHandler, FormHandlerParams } from "./use-form";
import updateDeviceSettings from "@/gql/update-device-settings";
import { clearAssetUpdate, markPropertyComplete, startAssetUpdate } from "@/config/asset";
import { getPropertyConfig } from "@/utils/properties";
import features from "@/config/features";

export interface AssetFormHandlerParams extends FormHandlerParams {
  asset: MaybeRef<DecoratedAsset>;
  properties: FieldDescriptor[];
}

export class AssetFormHandler extends FormHandler {
  asset: MaybeRef<DecoratedAsset>;
  properties: FieldDescriptor[];
  dependencyProperties: FieldDescriptor[];

  constructor(form: Ref<Form>, params: AssetFormHandlerParams) {
    super(form, { descriptors: params.properties });
    this.asset = params.asset;
    this.properties = params.properties;
    this.dependencyProperties = this.getDependencies();
  }

  getFormConfig(): Partial<FormConfig> {
    return buildAssetFormConfig(unref(this.asset).config);
  }

  populateForm(force = false): void {
    const allDescriptors = [...this.properties, ...this.dependencyProperties];
    for (const property of allDescriptors) {
      copyPropertyToForm(unref(this.asset), this.form.value, property, force);
    }
  }

  afterFirstPopulate(): void {
    super.afterFirstPopulate();
    watch(unref(this.asset).properties, () => this.populateForm(), { deep: true });
  }

  async submit(
    values: FormValues,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    submitSelection: SubmitSelection,
    serverParams: FormSubmitServerParams
  ): Promise<boolean> {
    let success = false;
    startAssetUpdate(unref(this.asset), "update", Object.keys(values));

    await updateDeviceSettings(unref(this.asset).assetUuid, values, serverParams)
      .then(result => {
        success = true;

        if (features.stateManagement) {
          unref(this.asset).locked = result.deviceLock;
          result.ignoredProperties.forEach(key => {
            markPropertyComplete(unref(this.asset), key);
          });
        }
      })
      .catch(() => {
        success = false;
        clearAssetUpdate(unref(this.asset));
      });

    return success;
  }

  getDependencies(): FieldDescriptor[] {
    const dependencies = this.properties.flatMap(
      descriptor => getPropertyConfig(unref(this.asset).config, descriptor).dependsOnFields
    );
    return uniq(dependencies);
  }
}
